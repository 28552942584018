<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Clientes"
      subtitle="| Administrador de Clientes"
      class="heading-block"
    >
      <template #extra>
        <div class="mt-5 mt-sm-0 ml-sm-3">
          <b-button
            variant="alt-primary"
            class=""
            href="javascript:void(0)"
            v-click-ripple
            v-b-popover.hover.bottom="'Alta de Cliente'"
            @click="ShowModalCliente()"
            style="width: 200px"
          >
            <i class="fa fa-plus-square mr-1"></i> Nuevo cliente
          </b-button>
          <!-- Small Block Modal -->
          <b-modal
            id="modal-clientes"
            size="lg"
            body-class="p-0"
            hide-footer
            hide-header
          >
            <div
              class="block block-rounded block-themed block-transparent mb-0"
            >
              <div class="block-header bg-primary-dark">
                <h3 class="block-title">
                  <i class="fa fa-user mr-1"></i> Alta de cliente
                </h3>
                <div class="block-options">
                  <button
                    type="button"
                    class="btn-block-option"
                    @click="ShowModalCliente()"
                  >
                    
                  </button>
                  <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-clientes')"></i>
                </div>
              </div>
              <div class="block-content font-size-sm">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-row class="ml-4 mt-3">
                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          # Cliente <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="no_cliente"
                          name="no_cliente"
                          placeholder="-"
                          size="sm"
                          v-model="form.no_cliente"
                          :disabled="view_cliente == true"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="ml-4 mt-1">
                    <b-col sm="4">
                      <b-form-group label-for="username">
                        <template #label>
                          Tipo de Cliente <span class="text-danger">*</span>
                        </template>
                        <template v-if="view_cliente==false">
                            <b-form-select
                              id="input-small"
                              size="sm"
                              v-model="form.tipo_cliente"
                              :disabled="view_cliente==true"
                            >
                              <option :value="data.id"  v-for="data in type_clients" :key="data.id">{{ data.name }}</option>
                            </b-form-select>
                        </template>
                        <template v-else>
                            <b-form-input
                              id="username"
                              name="username"
                              :value="type_clients[0].name"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                        </template>                        
                      </b-form-group>
                    </b-col>

                    <b-col sm="4">
                      <b-form-group label-for="username">
                        <template #label>
                          Nombre Comercial <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="username"
                          name="username"
                          placeholder="Ingresa nombre comercial de cliente.."
                          v-model="form.nombre_comercial"
                          :disabled="view_cliente==true"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col sm="4">
                      <b-form-group label-for="username">
                        <template #label>
                          Razon Social <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="username"
                          name="username"
                          placeholder="Ingresa razon social de cliente.."
                          v-model="form.razon_social"
                          :disabled="view_cliente==true"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <br />
                  <div class="block-content border-top">
                    <div class="block-header block-header-default">
                      <h3 class="block-title" style="text-align: left">
                        Contacto
                      </h3>
                      <b-button class="mr-1" @click="create_contacto()" v-if="view_cliente==false"
                        ><i class="fa fa-plus"></i> Agregar</b-button
                      >
                    </div>

                    <div class="card" v-for="(data, index) in form.contacto" :key="data.index">
                      <i class="fa fa-trash"  v-if="view_cliente==false" @click="removercontacto(index)" style="cursor: pointer;position: absolute;float: left;left: 92%;background: black;padding: 9px;border-radius: 20px;color: white;margin-top: -15px;"></i>
                      <b-row class="ml-2 mt-5">
                        <b-col sm="6">
                          <b-form-group label-for="username">
                            <template #label>
                              Nombre
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder="Ingresa nombre y apellidos de contacto.."
                              v-model="data.nombre"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group label-for="username">
                            <template #label>
                              Telefono
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder="Ingresa telefono de contacto.."
                              v-model="data.telefono"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row class="ml-2">
                        <b-col sm="6">
                          <b-form-group label-for="username">
                            <template #label>
                              Email
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder="Ingresa email de contacto.."
                              v-model="data.email"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group label-for="username">
                            <template #label>
                              Departamento
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder="Ingresa departamento de contacto.."
                              v-model="data.departamento"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <br />
                  <div class="block-content border-top">
                    <div class="block-header block-header-default">
                      <h3
                        class="block-title"
                        style="text-align: left !important"
                      >
                        Domicilio
                      </h3>
                      <b-button class="mr-1" @click="create_domicilio()" v-if="view_cliente==false"
                        ><i class="fa fa-plus"></i> Agregar</b-button
                      >
                    </div>
                    <div class="block-content font-size-sm"  v-for="(data, index) in form.domicilio" :key="data.index">
                      <i class="fa fa-trash" v-if="view_cliente==false"  @click="removerdomicilio(index)" style="cursor: pointer;position: absolute;float: left;left: 92%;background: black;padding: 9px;border-radius: 20px;color: white;margin-top: -15px;"></i>
                      <b-row class="ml-2 mt-5">
                        <b-col sm="8">
                          <b-form-group label-for="username">
                            <template #label>
                              Calle
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder="Ingresa Calle del cliente"
                              v-model="data.calle"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="2">
                          <b-form-group label-for="username">
                            <template #label>
                              No. Int.
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder=""
                              v-model="data.no_int"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="2">
                          <b-form-group label-for="username">
                            <template #label>
                              No. Ext.
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder=""
                              v-model="data.no_ext"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row class="ml-2">
                        <b-col sm="6">
                          <b-form-group label-for="username">
                            <template #label>
                              Colonia
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder="Ingresa Colonia del Cliente.."
                              v-model="data.colonia"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group label-for="username">
                            <template #label>
                              Ciudad
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder="Ingresa Ciudad del cliente.."
                              v-model="data.ciudad"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row class="ml-2">
                        <b-col sm="6">
                          <b-form-group label-for="username">
                            <template #label>
                              Estado
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder="Ingresa Estado del cliente.."
                              v-model="data.estado"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group label-for="username">
                            <template #label>
                              Codigo Postal
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              id="username"
                              name="username"
                              placeholder=""
                              v-model="data.codigo_postal"
                              :disabled="view_cliente==true"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <br />
                  <div class="block-content text-left border-top">
                    <b-row class="ml-2">
                      <b-col sm="12">
                          <b-form-group label-for="comentario">
                            <template #label>
                              Comentario
                              <span class="text-danger">*</span>
                            </template>
                            <b-textarea
                              id="input-small"
                              size="xl"
                              placeholder="Ingresa comentario"
                              v-model="form.comentarios"
                              :disabled="view_cliente==true"
                              rows="5"
                            ></b-textarea>
                          </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <br />
                </form>
              </div>
              <div
                class="block-content block-content-full text-right border-top"
              >
                <b-button
                  variant="alt-primary"
                  class="mr-1"
                  @click="$bvModal.hide('modal-clientes')"
                  >Cerrar</b-button
                >
                <template v-if="view_cliente==false">
                     <template v-if="edit == false">
                      <b-button variant="primary" @click="CreateCustomer()"
                        >Guardar</b-button
                      >
                    </template>
                    <template v-else> 
                      <b-button variant="danger" @click="UpdateClientes()"
                        >Actualizar</b-button
                      >
                    </template>
                </template>
                   

              </div>
            </div>
          </b-modal>
          <!-- END Small Block Modal -->
        </div>
      </template>
    </base-page-heading>

    <div>
      <base-block rounded title="">
        <b-row>
          <b-col sm="6" xl="2">
            <b-dropdown
              id="dropdown-default-light"
              variant="light"
              text="Acciones agrupadas"
            >
              <b-dropdown-item class="font-size-sm"
                >Habilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm"
                >Deshabilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="font-size-sm"
                >Eliminar Seleccion</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" class="ml-3">
            <!-- Form Inline - Alternative Style -->
            <b-form inline>
              <b-form-input
                type="search"
                class="form-control-alt mr-sm-2 mb-sm-0"
                id="example-if-email2"
                name="example-if-email2"
                placeholder="Busqueda"
                v-model="filter"
              ></b-form-input>
            </b-form>
            <!-- END Form Inline - Alternative Style -->
          </b-col>

          <b-col>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right"
              right
            >
              <template #button-content>
                <i class="si si-settings"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="/clients.csv" download
                >Exportar Clientes a Excel</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="mr-1 float-right"
              right
            >
              <template #button-content>
                <i class="fas fa-fw fa-filter"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Nombre</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Departamento</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <br />
        <b-overlay :show="showoverlay" rounded="sm">
            <div class="table-responsive">
              <b-table :items="customers" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
                  
                  <template #cell(actions)="row">
                    <b-button size="sm" @click="ViewCliente(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-eye"></i>
                      </b-button>
                      <b-button size="sm" @click="EditCliente(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-pencil-alt"></i>
                      </b-button>
                      <b-button size="sm" @click="DeleteCliente(row.item.id)"  class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-times"></i>
                      </b-button>
                  </template>
              </b-table>
            </div>
            
        </b-overlay>
        <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
        </b-row>
      </base-block>
    </div>
  </div>
</template>

<script>
import TipoClientes from "../../../api/admin/clientes/tipo_clientes/Tipos_clientes";
import Clientes from "../../../api/admin/clientes/Clientes";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      customers: [],
      filter: null,
      timeout: null,
      view_cliente:false,
      edit : false,
      showoverlay:false,
      type_clients:[],
      fields: [
        {key: 'no_cliente',sortable: true, label: '# CLIENTE'},
        {key: 'nombre_comercial',sortable: true, label: 'NOMBRE'},
        {key: 'razon_social',sortable: true, label: 'RAZON SOCIAL'},
        {key: 'client_type', formatter: value => {
              return value.name
          },sortable: true, label: 'TIPO CLIENTE'},
        {key: 'actions',sortable: true, label: 'ACCIONES'},
      ],
      form: {
        id:"",
        no_cliente: "",
        nombre_comercial: "",
        razon_social: "",
        tipo_cliente:'',
        contacto :[{  
          nombre: "",
          telefono: "",
          email: "",
          departamento: "",
        }],
        domicilio:[{ 
            calle: "",
            no_int: "",
            no_ext: "",
            colonia: "",
            ciudad: "",
            estado: "",
            codigo_postal: "",
        }],        
        comentarios: "",
      },
      errors:[],
    };
  },
  computed: {
    totalRows() {
      return this.customers.length
    }
  },
  methods: {

    AllTypeCliente() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      TipoClientes.GetAllTypeClientes(auth)
        .then((response) => {
          this.type_clients = response.data.clients_types;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    AllCustomers() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      Clientes.GetAllCustomers(auth)
        .then((response) => {
          this.customers = response.data;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    CreateCustomer() {
      this.$bvModal.hide("modal-clientes");
      if (this.form.nombre_comercial && this.form.razon_social ) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          Clientes.PostCreateCustomer(this.form, auth)
            .then((response) => {
              this.AllCustomers();
              this.showoverlay = false;
              Swal.fire({
                title: "Genial!",
                text: response.data.message,
                icon: "success",
              });
              this.form = {
                  id:"",
                  no_cliente: "",
                  nombre_comercial: "",
                  razon_social: "",
                  contacto :[{  
                    nombre: "",
                    telefono: "",
                    email: "",
                    departamento: "",
                  }],
                  domicilio:[{ 
                      calle: "",
                      no_int: "",
                      no_ext: "",
                      colonia: "",
                      ciudad: "",
                      estado: "",
                      codigo_postal: "",
                  }],        
                  comentarios: "",
              };
              
            })
            .catch((error) => {
              const obj = error.response.data.errors;
              Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
              );
            });
      }else{
         this.errors = []
          if (!this.form.no_cliente) {
              let me=this
              me.errors.push('El número del cliente es requerido')
          }   
          if (!this.form.nombre_comercial) {
              let me=this
              me.errors.push('El nombre comercial es requerido')
          }     
          if (!this.form.razon_social) {
              let me=this
              me.errors.push('La razón social es requerido')
          }          
          Swal.fire({
            title: "Corrija los siguientes errores",
            icon: "error",
            html: this.errors
                .map(item => `<p> <li>${item}</li></p>`)
                .join('')
          });
      }
    },

    create_contacto() {
      this.form.contacto.push({
        nombre: "",
        telefono: "",
        email: "",
        departamento: "",
      });
    },

    create_domicilio() {
      this.form.domicilio.push({
        calle: "",
        no_int: "",
        no_ext: "",
        colonia: "",
        ciudad: "",
        estado: "",
        codigo_postal: "",
      });
    },

    removercontacto(x) {
        this.form.contacto.splice(x, 1);
    },

    removerdomicilio(x) {
        this.form.domicilio.splice(x, 1);
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

    ShowModalCliente() {
      this.form = {
                  id:"",
                  no_cliente: "",
                  nombre_comercial: "",
                  razon_social: "",
                  contacto :[{  
                    nombre: "",
                    telefono: "",
                    email: "",
                    departamento: "",
                  }],
                  domicilio:[{ 
                      calle: "",
                      no_int: "",
                      no_ext: "",
                      colonia: "",
                      ciudad: "",
                      estado: "",
                      codigo_postal: "",
                  }],        
                  comentarios: "",
      };
      this.$bvModal.show("modal-clientes");
      this.view_cliente=false;
      this.edit = false;
    },

    EditCliente(data) {
      this.$bvModal.show("modal-clientes");
      this.edit = true;
      this.view_cliente=false;
      this.form = {
        id: data.id,
        no_cliente : data.no_cliente,
        tipo_cliente:data.client_type_id,
        nombre_comercial : data.nombre_comercial,
        razon_social : data.razon_social,
        contacto : data.client_contacts,
        domicilio: data.client_addresses,
        comentarios : data.comentarios,
      };
    },

    ViewCliente(data) {
      this.$bvModal.show("modal-clientes");
      this.view_cliente=true;
      this.form = {
        no_cliente : data.no_cliente,
        nombre_comercial : data.nombre_comercial,
        razon_social : data.razon_social,
        contacto : data.client_contacts,
        domicilio: data.client_addresses,
        comentarios : data.comentarios,
      };
    },

    UpdateClientes(){
        this.$bvModal.hide("modal-clientes");
        this.showoverlay = true;
        let auth = JSON.parse(localStorage.autentication);
        Clientes.UpdateByClientes(this.form, auth)
        .then((response) => {
          this.AllCustomers();
          this.showoverlay = false;
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
          this.form = {
                  id:"",
                  no_cliente: "",
                  nombre_comercial: "",
                  razon_social: "",
                  contacto :[{  
                    nombre: "",
                    telefono: "",
                    email: "",
                    departamento: "",
                  }],
                  domicilio:[{ 
                      calle: "",
                      no_int: "",
                      no_ext: "",
                      colonia: "",
                      ciudad: "",
                      estado: "",
                      codigo_postal: "",
                  }],        
                  comentarios: "",
          };          
        })
        .catch((error) => {
          Swal.fire({
            title: "Que mal!",
            text: error.message,
            icon: "error",
          });
        });
    },

    DeleteCliente(id){
        Swal.fire({
            title: "¿Esta seguro de eliminar el registro?",
            text: "¿Eliminar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        })
        .then(resultado => {
            if (resultado.value) {
                 this.showoverlay = true;
                let auth = JSON.parse(localStorage.autentication);
                Clientes.DeleteByClientes(id, auth)
                .then((response) => {
                  this.AllCustomers();
                  this.showoverlay = false;
                  Swal.fire({
                    title: "Genial!",
                    text: response.data.message,
                    icon: "success",
                  });
                  
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Que mal!",
                    text: error.message,
                    icon: "error",
                  });
                });
            } else {
                // Dijeron que no
                console.log("*NO se elimina la venta*");
            }
        });

        
    },
  },

  mounted() {
    let me = this;
    me.AllTypeCliente();
    me.AllCustomers();
  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>
