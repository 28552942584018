import Api from "../../../Api";

const END_POINT_AllTYPECLIENTES = 'user/clients/client-types/all';
const END_POINT_CREATETYPECLIENTES = 'user/clients/client-types/create';
const END_POINT_UPDATETYPECLIENTES = 'user/clients/client-types/update';
const END_POINT_DELETETYPECLIENTES = 'user/clients/client-types/delete';



export default {

    GetAllTypeClientes(data) {
        return Api.get(END_POINT_AllTYPECLIENTES, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },
    PostCreateTypeCliente(data, auth) {
        return Api.post(END_POINT_CREATETYPECLIENTES, {
            "name": data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },

    UpdateByTypeClientes(data, auth) {
        return Api.patch(END_POINT_UPDATETYPECLIENTES, {
            'id': data.id,
            "name": data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByTypeClientes(id, auth) {
        return Api.delete(END_POINT_DELETETYPECLIENTES + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }
}